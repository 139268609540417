
























































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import AddDeleteTable from "@/components/Table/AddDeleteTable.vue";
import SpecEntity from "@/entities/specs/spec-entity";
import SpecManufactureProcessEntity, {
  ProcessNameDict, SpecManufactureProcessManagementPointDict,
  ValidatorRules,
  ProcessHazardDict
} from "@/entities/specs/spec-manufacture-process-entity";
import { help as PopoverText } from '@/lang/help/spec-create';
import ElTableDraggable from "@/components/Table/SortableTable/ElTableDraggable.vue";
import {listenCursorInputOnTable} from "@/components/mixins/TableCursorHandler";

@Component({
  components: {
    AddDeleteTable,
    ElTableDraggable,
  },
})
export default class ManufactureProcessTable extends Vue {
  @Prop({required: true}) private spec!:SpecEntity;
  @Prop({required: true}) private isDetail!:boolean;

  private readonly PopoverText = PopoverText;
  private readonly rules = ValidatorRules;
  private readonly ProcessNameDict = ProcessNameDict;
  private readonly ProcessHazardDict = ProcessHazardDict;
  private readonly SpecManufactureProcessManagementPointDict = SpecManufactureProcessManagementPointDict;

  created() {
    if (this.spec.manufactureProcesses.length === 0) {
      this.addProcess();
    }
  }

  private addProcess() {
    this.spec.manufactureProcesses.push(new SpecManufactureProcessEntity());
  }
  private row(scope: { row: SpecManufactureProcessEntity }) {
    return scope.row;
  }
}
