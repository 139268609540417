import { render, staticRenderFns } from "./Basic.vue?vue&type=template&id=6ef6f4e7&scoped=true&"
import script from "./Basic.vue?vue&type=script&lang=ts&"
export * from "./Basic.vue?vue&type=script&lang=ts&"
import style0 from "./Basic.vue?vue&type=style&index=0&id=6ef6f4e7&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ef6f4e7",
  null
  
)

export default component.exports