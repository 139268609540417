




































































import {Component, Prop} from 'vue-property-decorator';
import SpecEntity, {SpecCreateBaseEntity, ValidatorRules} from "@/entities/specs/spec-entity";
import LoadingHandler from "@/utils/loading-handler";
import {ISpecSubmissionRequest} from "@/entities/specs/spec-submission-entity";
import {SpecShareEntity} from "@/entities/specs/spec-share-entity";
import CreateSpecPageBase, {PageComponents} from "@/views/spec/companies/components/spec-components/create/components/CreateSpecPageBase";

import SubmissionModalWithSpec from "./components/SubmissionModalWithSpec.vue";
import SpecRepository from "@/repositories/spec/company/spec-repository";
import {CompanyRepository} from "@/repositories/company-repository";
import {notEmptyArrayRule, requiredRule} from "@/utils/validation-rules";
import SpecShareForSubmitterRepository from "@/repositories/spec/company/spec-share-for-submitter-repository";

import SpecTab from "@/views/spec/companies/components/spec-components/create/components/SpecTab.vue";
import {ApprovalService} from "@/services/approval-service";
import {setInitialValueFromRequiredFields} from "@/entities/specs/spec-share-request-entity";
import {HandledApiError, HttpStatusCode} from '@/bootstraps/error-handler';
import PublishModal from "@/views/spec/components/PublishModal.vue";
import CompanyEntity from "@/entities/company-entity";
import { emitSpecSavingEvent } from "@/views/spec/companies/events/on-spec-saving-event";

@Component({
  components: {
    PublishModal,
    SubmissionModalWithSpec,
    SpecTab,
    ...PageComponents,
  }
})
export default class extends CreateSpecPageBase {
  @Prop({required: true}) private specShareId!:number;
  @Prop({required: false, default: null}) private specId!:number|null;

  private specShare:SpecShareEntity = null as any;
  protected model:SpecCreateBaseEntity = null as any;
  private company: CompanyEntity = null as any;

  private publishModalVisible = false;

  private submissionModalVisible = false;
  private submissionModalError = '';
  protected approvalRequired:boolean|null = null;

  private rules:any = {};

  protected initialized = false;


  public get isCreateMode(): boolean {
    return this.$route.name === 'submission.create';
  }

  private async created() {
    LoadingHandler(async () => {
      this.specShare = await (new SpecShareForSubmitterRepository(this.companyId)).findSpecShare(this.specShareId);

      if (!this.specShare.canCreateSubmission) {
        return this.$router.push({name: `submission.index`}, () => {
          this.$message({type: 'warning', message: this.$t(`提出可能なステータスではありません`) });
        });
      }

      this.rules = this.getRulesWithRequired();

      await Promise.all([
        (new Promise<void>(async (resolve, reject) => {
          if (this.specId) {
            this.model = await this.load();
          } else {
            this.model = new SpecCreateBaseEntity();
          }
          resolve();
        })),
        (new CompanyRepository().find(this.companyId)).then(company => {
          this.company = company;
          this.approvalRequired = (new ApprovalService(company, true)).approvalSettingEnabled();
        })
      ]);

      setInitialValueFromRequiredFields(this.model, this.specShare.request.requiredFields)

      this.initialized = true;
    });
  }

  private getRulesWithRequired() {
    // TODO: requiredで元のルールが上書きされてしまっているので、keyごとにconcatされるようにする
    return Object.assign({}, ValidatorRules, this.specShare.request.getRequiredFieldsAsObject([ requiredRule ]));
  }

  protected onAllTabLoaded() {
    if (this.$route.query.validate) {
      this.$nextTick(() => {
        this.validate(this.$refs.form);
      });
    }
  }

  private async load() {
    return (await (new SpecRepository(this.companyId)).find(this.specId!)).toSpecUpdateEntity();
  }

  private async saveDraft() {
    try {
      this.rules = ValidatorRules;
      await this.$nextTick();
      if (!(await this.validate(this.$refs.form))) return false;
    } finally {
      this.rules = this.getRulesWithRequired();
    }

    const data = this.model!.serialize();
    emitSpecSavingEvent(data);

    const newSpec = await LoadingHandler(async () => {
      return (this.isCreateMode ?
          await (new SpecRepository(this.companyId)).create(data) :
          await (new SpecRepository(this.companyId)).update(this.specId, data)
      ).data;
    }, 30000);

    this.$message({type: 'success', message: this.$t('xを下書き保存しました。', [ this.$t('規格書') + `「${this.model!.name}」`] ) });
    this.forward({name: 'submission.revise', params: {specId: newSpec.id.toString() }}).then(() => {
      return LoadingHandler(async () => {
        const s = await this.load();
        this.setAnotherSpec(s);
      });
    });
  }

  private async showPublishModal() {
    if (!(await this.validate(this.$refs.form))) return false;
    this.publishModalVisible = true;
  }
  private get publishModalDescription(): string {
    return this.isCreateMode ?
      this.$t('この規格書は提出後、編集することができます。編集すると、新しい版の内容を提出先に配信することができます。') :
      this.$t('この規格書をすでにいずれかの組織に提出している場合、既存の提出先に対しても規格書の更新が配信されます。これを避けたい場合、別の規格書を作成し、提出する必要があります。<br><br>しかし、他社に対する規格書の更新を効率化するため、一つの規格書で管理することをオススメいたします。一つの規格書でも、非公開情報は提出先に応じて設定することできます。');
  }

  private async showSubmitModal() {
    if (!(await this.validate(this.$refs.form))) return false;
    this.submissionModalError = '';
    this.submissionModalVisible = true;
  }

  private async onSubmit(data:{data: ISpecSubmissionRequest, selectedSpec:SpecEntity}) {
    const specData = this.model!.serialize();
    emitSpecSavingEvent(specData);
    const submission = data.data;

    try {
      await LoadingHandler(async () => {
        return this.isCreateMode ?
          await (new SpecShareForSubmitterRepository(this.companyId)).saveSpecSubmissionWithCreatingSpec(submission, specData) :
          await (new SpecShareForSubmitterRepository(this.companyId)).saveSpecSubmissionWithUpdatingSpec(submission, this.specId!, specData);
      }, 30000)
    } catch (error) {
      if (error instanceof HandledApiError && error.httpStatus === HttpStatusCode.Raw) {
        this.submissionModalError = error.message;
        this.showRequiredFieldError(error);
        return;
      }
      throw error;
    }

    this.forward({name: `submission.index`}).then(() => {
      this.$message({type: 'success', message: this.$t(`規格書を提出しました`)});
    });
  }

  // TODO: SubmissionListと共通化
  private showRequiredFieldError(error: HandledApiError) {
    const count = error.message.split("\n").length;
    this.$message({type: 'error', message: this.$t(`この提出で入力が不足している項目が${count}個あります`)});
  }
}
