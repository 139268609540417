





















import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {getTextByLang, i18n} from "@/bootstraps/locale";
import InputAttachment from "@/views/spec/companies/components/spec-components/create/components/InputAttachment.vue";
import {SpecBaseEntity} from "@/entities/specs/spec-entity";
import {SpecPackageImagePreparedLang} from "@/lang/enum/spec-package-image-prepared-lang";
import {SpecDocumentAttachmentPreparedLang} from "@/lang/enum/spec-document-attachment-prepared-lang";
import {
  RequiredFieldKey,
  RequiredFieldKeyPrefixSpecPackageImagePrepared
} from "@/entities/specs/spec-share-request-entity";

  @Component({
    components: {
      InputAttachment,
    },
  })
  export default class MultipleAttachmentSelect extends Vue {
    @Prop({required: true}) private spec!:SpecBaseEntity;
    @Prop({required: true}) private value!:string[];
    @Prop({type: Boolean, required: true}) private isPackageImage!:boolean;
    @Prop({type: Object, required: true}) private selection!:object;
    @Prop({type: String, required: true}) private title!:string;
    @Prop({type: String, required: true}) private help!:string;
    @Prop({default: () => []}) private requiredFields!:RequiredFieldKey[];

    private get cValue() {
      return this.value;
    }
    private set cValue(val) {
      this.$emit('input', val);
    }
    private translate(key:string) {
      const dict = this.isPackageImage ? SpecPackageImagePreparedLang : SpecDocumentAttachmentPreparedLang;
      return (key in dict) ? getTextByLang(dict[key]) : key;
    }

    private isRequired(key: string): boolean {
      const fields = this.requiredFields.map((v) => v.replace(RequiredFieldKeyPrefixSpecPackageImagePrepared, ""));
      return fields.includes(key);
    }
  }
