
















































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {FoodTypeForSpec, SpecCreateBaseEntity} from "@/entities/specs/spec-entity";
import {help as PopoverText} from '@/lang/help/spec-create';
import {MakerType, MakerTypeDict, PartnerEntity, PartnerType, SpecPartnerEntity} from "@/entities/specs/partner-entity";
import PartnerRepository from "@/repositories/spec/company/partner-repository";
import PartnerForm from './components/PartnerForm.vue';
import PartnerFactoryForm from './components/PartnerFactoryForm.vue';
import CompanyEntity from "@/entities/company-entity";
import {PartnerFactoryEntity, SpecPartnerFactoryEntity} from "@/entities/specs/partner-factory-entity";
import CustomValueCreateSection
  from "@/views/spec/companies/components/spec-components/create/components/CustomValueCreateSection.vue";
import {SpecCustomValueCategory} from '@/entities/specs/spec-custom-value-entity';
import PartnerSelect from "@/components/Project/PartnerSelect.vue";
import CompanySpecCustomValueSettingEntity from "@/entities/company-spec-custom-value-setting-entity";
import CustomValueInnerTable from "@/views/spec/companies/components/CustomValue/CustomValueInnerTable.vue";
import MadeInAreaRepository from "@/repositories/master/made-in-area-repository";
import {AreaSuffixForProcess, MadeInAreaEntity} from "@/entities/ingredient-made-in-area-entity";
import FilterableMultipleSelect from "@/components/FilterableSelect/FilterableMultipleSelect.vue";
import {SpecAreaEntity} from "@/entities/specs/spec-area-entity";

@Component({
  components: {
    FilterableMultipleSelect,
    CustomValueInnerTable,
    PartnerSelect,
    CustomValueCreateSection,
    PartnerForm,
    PartnerFactoryForm
  },
})
export default class Company extends Vue {
  @Prop({required: true}) private model!:SpecCreateBaseEntity;
  @Prop({required: false, default: false}) private isSelfSubmission!:boolean;
  @Prop({required: true}) private company!:CompanyEntity;
  @Prop({required: false}) private customValueSettings?:CompanySpecCustomValueSettingEntity[];

  private readonly PopoverText = PopoverText;
  private readonly PartnerType = PartnerType;
  private readonly MakerTypeDict = MakerTypeDict;
  private readonly SpecCustomValueCategory = SpecCustomValueCategory;
  private readonly FoodTypeForSpec = FoodTypeForSpec;

  private partners:PartnerEntity[]|null = null;
  private initialized = false;

  private madeInAreas: MadeInAreaEntity[] = [];

  private readonly SELLER_ID_SAME_AS_COMPANY = -1;
  private readonly SELLER_ID_NEW = null;
  private readonly MAKER_ID_ON_NEW = null;

  private filterPartners(type:PartnerType): PartnerEntity[] {
    return (this.partners || []).filter(p => p.type === type);
  }

  private async created() {
    await Promise.all([
      (new PartnerRepository(this.company.id)).all().then(data => {
        this.partners = data;
      }),
      (new MadeInAreaRepository).findAllWithCache().then((list:MadeInAreaEntity[]) => {
        this.madeInAreas = list;
      }),
    ]);

    this.init();

    this.$emit('initialized');
    this.initialized = true;
  }

  @Watch('model')
  private init() {
    if (this.partners === null) {
      return;
    }

    if (this.isSelfSubmission) {
      if (!this.model.seller.id) {
        this.initSeller();
      }
    } else {
      this.model.seller = new SpecPartnerEntity({referencePartnerId: this.SELLER_ID_SAME_AS_COMPANY as any,})
        .import(this.company);
    }

    // SelectBoxで選択されている状態にするため、reference_partner_idの値をidとする。
    const maker = this.partners!.find(p => p.id === this.model.maker.referencePartnerId);
    if (this.model.maker.id && !!maker) {
      this.model.maker = new SpecPartnerEntity({id: this.model.maker.referencePartnerId}).import(maker);
    } else if (!!this.model.maker.id) {
      // 引用のときに、他社の規格書を引用している場合、内容はcopyするが新規作成状態にする
      this.model.maker.id = this.MAKER_ID_ON_NEW as any;
    } else {
      // それ以外の場合、または該当するpartnerがマスタから削除されている場合
      this.initMaker();
    }

    if (this.model.makerFactory) {
      if (this.getFactoriesOfSelectedMaker().find(f => f.id === this.model.makerFactory!.referencePartnerFactoryId)) {
        this.model.makerFactory.id = this.model.makerFactory.referencePartnerFactoryId;
      } else {
        // 引用のときに、他社の規格書を引用している場合、内容はcopyするが新規作成状態にする
        this.model.makerFactory.id = null as any;
      }
    } else {
      this.initFactory();
    }
  }

  private initSeller() {
    this.model.seller = new SpecPartnerEntity({referencePartnerId: this.SELLER_ID_NEW as any});
  }
  private initMaker() {
    this.model.maker = new SpecPartnerEntity({ id: this.MAKER_ID_ON_NEW as any });
  }
  private initFactory() {
    this.model.makerFactory = new SpecPartnerFactoryEntity({ id: null as any});
  }
  private get makerFactory() {
    return this.model.makerFactory!;
  }

  private getFactoriesOfSelectedMaker(): PartnerFactoryEntity[] {
    const selectedMaker = this.partners!.find(p => p.id === this.model.maker!.id);
    if (!selectedMaker) return [];
    return selectedMaker.factories;
  }

  private onSellerChanged(val:number|null) {
    if (val === this.SELLER_ID_NEW) {
      this.initSeller();
    } else {
      const seller = this.partners!.find(p => p.id === this.model.seller.referencePartnerId)!;
      this.model.seller.import(seller);
    }
  }
  private onMakerChanged(val:number|null) {
    if (val === this.MAKER_ID_ON_NEW) {
      this.initMaker();
    } else {
      const maker = this.partners!.find(p => p.id === this.model.maker.id)!;
      this.model.maker.import(maker);
    }

    if (this.model.makerFactory!.id !== null) {
      this.initFactory();
    }
  }
  private onFactoryChanged(val:number|null) {
    if (val === null) {
      this.initFactory();
    } else {
      const factory = this.getFactoriesOfSelectedMaker().find(p => p.id === this.model.makerFactory!.id)!;
      this.model.makerFactory!.import(factory);
    }
  }

  private onMakerTypeChanged() {
    if (!this.initialized) return;
    if (this.model.maker.id !== this.MAKER_ID_ON_NEW) {
      // マスタからmakerが選択されている場合、makerTypeが変わると参照できなくなるので、リセットする
      this.initMaker();
    }
    if (this.model.makerFactory!.id !== null) {
      this.initFactory();
    }
  }

  private deleteAreas() {
    this.model.areas.splice(0, this.model.areas.length);
  }
  private get madeInAreaModel() {
    return this.model.areas.map(a => {
      if (this.madeInAreaSelection.find(s => s.value === a.name) || a.name.endsWith(AreaSuffixForProcess)) {
        return a.name;
      } else {
        return a.name + (this.model.foodType === FoodTypeForSpec.Process ? AreaSuffixForProcess : '');
      }
    });
  }
  private set madeInAreaModel(val: string[]) {
    this.deleteAreas();
    this.model.areas.push(...val.map(a => new SpecAreaEntity({ name: a })));
  }
  private get madeInAreaSelection() {
    const extract = (a:MadeInAreaEntity) => {
      return {
        value: a.name,
        label: a.getLabelDisplayName(this.model.foodType === FoodTypeForSpec.Process),
        searchKey: a.searchKey,
      };
    };

    // TODO: LANG
    const selection:any = [];
    selection.push({value: '国内', label: '国内', searchKey: '国内', isHeading: true});
    selection.push(...this.madeInAreas.filter(a => a.isJapanDomestic).map(extract));
    selection.push({value: '海外', label: '海外', searchKey: '海外', isHeading: true});
    selection.push(...this.madeInAreas.filter(a => !a.isJapanDomestic).map(extract));
    return selection;
  }
  private setAreaFromFactory() {
    this.deleteAreas();

    if (this.makerFactory.prefecture) {
      this.model.areas.push(new SpecAreaEntity({ name: this.makerFactory.prefecture }));
    }
  }
}
